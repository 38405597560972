import React, { useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// Components
import { DetermineModuleToDisplay } from "../components/utils/determine-module-to-display";
import { ContentContainer } from "../components/containers/container";
import { PageTitle } from "../components/text/page-title";
import { Spacer } from "../components/utils/spacer";

// Colors
import { tertiary } from "../components/utils/colors";

// Context
import { FooterColor } from "../components/context/footer-color";

const Page = styled.div`
	width: 100%;
`;

const CareGuideContent = ({ data, pageContext }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const content = data.prismicCareGuide.data.body.map((content, outerIndex) => {
		return (
			<ContentContainer key={`collection_module_wrapper_${outerIndex}`}>
				<DetermineModuleToDisplay
					content={content}
					pageType={`buying-guide`}
					article={data.prismicCareGuide}
					contentIndex={outerIndex}
				/>
			</ContentContainer>
		);
	});

	return (
		<>
			<Page>
				<Helmet
					title={`${data.prismicCareGuide.data.seo_title}`}
					meta={[
						{
							name: "og:title",
							content: `${data.prismicCareGuide.data.seo_title}`,
						},
						{
							name: "description",
							content: `${data.prismicCareGuide.data.seo_description}`,
						},
						{
							name: "og:description",
							content: `${data.prismicCareGuide.data.seo_description}`,
						},
						{
							name: "twitter:title",
							content: `${data.prismicCareGuide.data.seo_title}`,
						},
						{
							name: "twitter:description",
							content: `${data.prismicCareGuide.data.seo_description}`,
						},
					]}
				/>
				<PageTitle title={data.prismicCareGuide.data.title.html} />
				{content}
			</Page>
			<Spacer />
		</>
	);
};

const CareGuide = ({ data, pageContext }) => (
	<CareGuideContent data={data} pageContext={pageContext} />
);

export default withPreview(CareGuide);

export const query = graphql`
	query CareGuide($uid: String!) {
		prismicCareGuide(uid: { eq: $uid }) {
			prismicId
			data {
				title {
					html
					text
				}
				seo_title
				seo_description
				body {
					... on PrismicCareGuideBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicCareGuideBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
							}
							link {
								url
								type
							}
							video_position
							video_size
						}
					}
					... on PrismicCareGuideBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicCareGuideBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
							video {
								width
								height
								html
								embed_url
								title
							}
						}
					}
					... on PrismicCareGuideBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_columns
							text_position
						}
					}
					... on PrismicCareGuideBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicCareGuide {
										id
										url
										prismicId
										data {
											thumbnail {
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicCareGuideBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicCareGuideBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}

							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicCareGuideBodyEnquiryForm {
						id
						slice_type
						primary {
							enquiry_form {
								document {
									... on PrismicEnquiryForm {
										id
										data {
											form_title {
												html
											}
											form_text {
												html
											}
											form_message_placeholder
											form_disclaimer_text {
												html
											}
											dropdown_options {
												dropdown_option
												linked_email_address
											}
											additional_form_fields {
												additional_form_field
											}
											enable_file_upload
											file_upload_text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicCareGuideBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicCareGuideBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicCareGuideBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicCareGuideBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
				}
			}
		}
	}
`;
